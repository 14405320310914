import React from 'react';

export default function About({ title, color }) {
  const Subtitle = ({ children }) => (
    <p
      className={`font-karla tracking-wider text-${color}-300 sm:text-4xl text-2xl`}
    >
      {children}
      <br />
    </p>
  );

  const Text = ({ children }) => (
    <p className="font-karla tracking-wider text-gray-500 sm:text-lg text-sm">
      {children}
      <br />
      <br />
    </p>
  );

  const Link = ({ to, children }) => (
    <a
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      className={`hover:bg-gray-600 hover:text-gray-300 bg-gray-800 font-karla tracking-wider text-gray-500 sm:text-lg text-sm`}
    >
      {children}
    </a>
  );

  const Code = ({ children }) => (
    // eslint-disable-next-line
    <a className="font-karla tracking-wider text-gray-300 sm:text-lg text-sm bg-blue-900">
      {children}
    </a>
  );

  return (
    <div className="w-full">
      <div className="w-full flex items-center mb-4">
        <div
          className={`font-nanum tracking-widest font-extrabold text-gray-300 sm:text-4xl text-2xl text-lg border-${color}-400 border-l-12 border-b-2 pl-2 sm:w-1/5`}
        >
          {title}
        </div>
      </div>
      <div className="sm:overflow-hidden overflow-y-scroll sm:w-5/6 w-full rounded pt-6 px-4 border-2 border-gray-900 mb-4 sm:mx-0 mx-auto bg-gray-900">
        <Subtitle>Who's behind this?</Subtitle>
        <Text>
          Hi! I'm <Link to="https://portfolio.kinesis.games">Kishan</Link> and I
          love learning new things, joking, programming and crafting{' '}
          <Link to="https://hub.kinesis.games">interactive projects</Link>.
        </Text>

        <Subtitle>Project Details</Subtitle>
        <Text>
          This website is an open-source project and a showcase of what can be
          done with <Link to="https://reactjs.org/">React</Link> and{' '}
          <Link to="https://tailwindcss.com/">Tailwind</Link> pushing{' '}
          <Link to="https://en.wikipedia.org/wiki/Create,_read,_update_and_delete">
            CRUD Operations
          </Link>{' '}
          to a backend running on the awesome{' '}
          <Link to="https://deno.land/">Deno</Link> and making use of{' '}
          <Link to="https://www.mongodb.com/">MongoDB</Link>. Of course,
          everything has been set up to work inside{' '}
          <Link to="https://www.docker.com">Docker</Link> containers and only 1
          command needs to be executed to get the full stack up and running.
        </Text>

        <Text>
          The initial aim was to create a clone of the{' '}
          <Link to="https://papa-heroes-react.azurewebsites.net/heroes">
            website which motivated me to learn React
          </Link>{' '}
          but it ended up to be better.
        </Text>

        <Subtitle>I WANT THE SOURCE CODE PLEASE!</Subtitle>
        <Text>
          Sure, provided you have{' '}
          <Link to="https://www.docker.com">Docker</Link> already installed on
          your machine, the steps simply consist of cloning the{' '}
          <Link to="https://github.com/EdgeKing810/Entities">
            Project from GitHub
          </Link>
          , going inside the <Code>Entities</Code> folder and running a{' '}
          <Code>docker-compose up</Code>.
        </Text>
      </div>
    </div>
  );
}
