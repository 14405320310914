import React, { useState, useContext, useEffect } from 'react';
import {
  useHistory,
  useLocation,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import { Context } from '../Context';

import List from './List';
import Add from './Add';
import Edit from './Edit';
import About from './About';

export default function SideNavbar() {
  const { navID, setNavID, navElements, reset } = useContext(Context);
  const [listOpen, setListOpen] = useState(true);

  const routes = navElements.map((nav) =>
    nav.context.length > 0 ? (
      <Switch key={`switch-${nav.path}`}>
        <Route exact path={`/${nav.path}`}>
          <List
            title={nav.title}
            color={nav.color}
            prev={nav.path}
            context={nav.context}
            reset={reset}
          />
        </Route>
        <Route exact path={`/${nav.path}/edit/:itemID`}>
          <Edit
            title={nav.title}
            color={nav.color}
            prev={nav.path}
            context={nav.context}
          />
        </Route>
        <Route exact path={`/${nav.path}/add`}>
          <Add
            title={nav.title}
            color={nav.color}
            prev={nav.path}
            context={nav.context}
          />
        </Route>
      </Switch>
    ) : null
  );

  const burger = () => (
    <button
      className="ml-2 px-2 flex items-center justify-center rounded-full hover:bg-gray-900 focus:bg-gray-900"
      onClick={() => setListOpen((prev) => !prev)}
    >
      <i className="ri-menu-line sm:text-2xl" />
    </button>
  );

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setNavID(0);

    const currentLocation = location.pathname.split('/')[1];
    navElements.forEach((nav, i) => {
      if (nav.path.toString() === currentLocation.toString()) {
        setNavID(i);
      }
    });
    // eslint-disable-next-line
  }, []);

  const list = navElements.map((nav) => (
    <div
      className={`sm:p-2 sm:py-2 py-2 sm:pl-4 my-1 sm:text-left text-center sm:w-4/5 w-1/2 font-karla tracking-wide font-bold text-gray-400 sm:text-sm text-xs sm:mr-8 rounded-lg ${
        nav.id === navID
          ? `bg-${nav.color}-600`
          : 'hover:bg-gray-700 focus:bg-gray-700'
      }`}
      onClick={() => {
        history.push(`/${nav.path}`);
        setNavID(nav.id);
      }}
      key={`nav-${nav.id}`}
    >
      {nav.title}
    </div>
  ));

  return (
    <div className="w-screen flex sm:flex-row flex-col sm:justify-around sm:items-start items-center">
      <div className="sm:w-1/5 w-5/6 flex flex-col sm:items-stretch items-center sm:mb-0 mb-2">
        <div className="font-mono tracking-widest sm:text-lg text-md text-gray-300 uppercase flex flex-col sm:text-left text-center mb-2">
          <div className="flex items-center">Menu {burger()}</div>
        </div>
        <div
          className={`transform w-full flex flex-col sm:items-start items-center ${
            listOpen
              ? 'translate-x-0 ease-out duration-300'
              : '-translate-x-screen ease-in duration-300 h-0'
          }`}
        >
          {list}
        </div>
      </div>
      <div className="sm:w-3/5 w-5/6">
        {routes}
        <Switch>
          <Route exact path="/">
            <Redirect to={navElements[0].path}></Redirect>
          </Route>
          <Route exact path="/about">
            <About
              title="About"
              color={navElements[navElements.length - 1].color}
            />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
